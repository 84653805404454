const dev = {
  STRIPE_KEY: "new",
  s3: {
    REGION: "eu-west-2",
    BUCKET: "wonder-cube-data"
  },
  apiGateway: {
    REGION: "eu-west-2",
    URL: "https://1241d4myh2.execute-api.eu-west-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_b3vuCFwqZ",
    APP_CLIENT_ID: "7644g1v4uo6e870s9ov427g166",
    IDENTITY_POOL_ID: "eu-west-2:348a7439-fc6a-45f2-a8a9-ecb4b87b12b5"
  }
};

const prod = {
  STRIPE_KEY: "new",
  s3: {
    REGION: "eu-west-2",
    BUCKET: "wonder-cube-data"
  },
  apiGateway: {
    REGION: "eu-west-2",
    URL: "https://1241d4myh2.execute-api.eu-west-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_b3vuCFwqZ",
    APP_CLIENT_ID: "7644g1v4uo6e870s9ov427g166",
    IDENTITY_POOL_ID: "eu-west-2:348a7439-fc6a-45f2-a8a9-ecb4b87b12b5"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
