import React, { Component } from "react";
import { API } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { s3Upload } from "../libs/awsLib";
import config from "../config";
import "./NewNote.css";

class Header extends Component {
 render() {
   return (
     <div>
       <label>
         King of Gaming?:
       </label>
       <input
         name="isSmiler"
         type="checkbox"
         onChange={this.props.handleChange} />
      </div>
   )
 }
}


// Allow for FF, Chrome, Safari and Mobile UserMedia
var MediaStream = window.MediaStream || window.webkitMediaStream;

if (typeof MediaStream !== 'undefined' && !('stop' in MediaStream.prototype)) {
    MediaStream.prototype.stop = function() {
        this.getAudioTracks().forEach(function(track) {
            track.stop();
        });

        this.getVideoTracks().forEach(function(track) {
            track.stop();
        });
    };
}


// Main Camera Component
class CameraComponent extends Component {

  constructor(props){
    super(props)
    this.info = null;

    this.mediaRecorder = null;
    this.usingMediaRecorder = null;
    // Make sure this is empty every time the component is mounted
    this.mediaChunks = [];

    // Callback function for the getUserMedia function
    this.gotMedia = this.gotMedia.bind(this);

    // When the user wants to trigger the livestream
    this.startRecording = this.startRecording.bind(this);

    //
    this.stopRecording = this.stopRecording.bind(this);

    // If an error happens getting the user media
    this.errorGettingMedia = this.errorGettingMedia.bind(this);

    // Set the stream object as null, so can be reset on component mount

    this.state = {
      stream: null,
    };

  }

  componentDidMount() {
    // Get the Audio and Video from the user
    navigator.getUserMedia({ video: true, audio: true }, this.gotMedia, this.errorGettingMedia)
  }

  errorGettingMedia(err){
    console.log(`Error getting stream from user.... Error: \n${err}`);
  }

  gotMedia(stream){

    this.setState({
      // Set the state's stream to the one returned from the machine
      stream: stream
    });

    this.usingMediaRecorder = window.MediaRecorder || false

    if(!this.usingMediaRecorder){
      console.log('Unable to record, as MediaRecorder is not enabled on this browser! :(');
    } else {

        // Media recorder init, needed to capture the video and audio channels
        let mediaRecorder = new MediaRecorder(stream, {mimeType: 'video/webm;codecs=h264'});

        // Listener for hen the Recorder is started
        mediaRecorder.ondataavailable = (ev) => {
          const self = this;


          // Async Function to get the buffer, and push it into the API gateway
          async function addData() {

            // Only stream to the server if the chunk has data and some size!
            if(ev.data && ev.data.size > 0) {

              const encodedBlob = new Blob([ev.data], { type: 'video/webm' })


              // DEVONLY: Store the newly buffered chunk into a local array
              self.mediaChunks = [...self.mediaChunks,  encodedBlob]

              // // Push the blob to the gateway
              // fetch(`/data`, {
              //   method: 'POST',
              //   body: JSON.stringify(
              //     {
              //       data: encodedBlob,
              //       streamerID: 'TEST',
              //       streamID: self.state.stream.id
              //     }
              //   )
              // })
              // .catch((err) => {
              //   self.errorGettingMedia(err);
              // })
            }
          }

          // Invoke the Async function
          addData();

        };

        // Bind the global mediaRecorder to the local one so it can be used in StartRecording
        this.mediaRecorder = mediaRecorder;


    }

    // Start the preview Screen
    const video = document.querySelector('#video');

    video.srcObject = this.state.stream;

    // DEVONLY: Log out stats about the media stream every second
    this.info = setInterval(() => {
      console.log(`Total Video Size: ${this.mediaChunks.length}`);
      console.log(this.mediaChunks[1]);
    }, 1000)


    // DEVONLY: Instantly start recording and emitting the stream.
    this.startRecording()

  }

  async stopRecording() {

    if(!this.usingMediaRecorder){
      this.state.stream.stop()
    } else {
      this.state.stream.stop()
      this.mediaRecorder.stop()
      var element = document.createElement("a");
      var file = new Blob(this.mediaChunks, {type: 'video/webm'});
      element.href = URL.createObjectURL(file);
      element.download = "download.webm";
      element.click();
    };
  };

  // Start the recording of the stream
  async startRecording() {
    // Trigger the media recorder to start

    if(!this.usingMediaRecorder){
      return;
    }

    this.mediaRecorder.start(10)

    // Tell the AWS Gateway that you are about to send stream data
    await fetch('/triggerStream', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        {
          streamerID: 'TEST',
          streamID: this.state.stream.id,
          startTime: Date.now()
        }
      )
    })
    .then((res) => {
      console.log('Triggered Stream with response: ', res);

      // If the trigger fails, throw a new error with info, and stop stream attempt
      if(res.status !== 200){
        throw new Error(`Failed to trigger stream, with info: \n\nStatus: ${res.status}\nStatus Text: ${res.statusText}\nURL: ${res.url}\nContent: ${JSON.stringify(res.body)}`)
      }

    })
    .catch((err) => {
      this.errorGettingMedia(err)
    })
  }

  componentWillUnmount() {
    clearInterval(this.info)
    this.stopRecording();
  };


  render() {
		return (
			<div>
        <video id="video" autoPlay controls></video>
			</div>
		);
	}

}


export default class NewNote extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      content: ""
    };
  }

  createNote(note) {
    return API.post("notes", "/notes", {
      body: note
    });
  }

  validateForm() {
    return this.state.content.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleFileChange = event => {
    this.file = event.target.files[0];
  }

  handleSubmit = async event => {
    event.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
      return;
    }

    this.setState({ isLoading: true });

    try {
      const attachment = this.file
        ? await s3Upload(this.file)
        : null;

      await this.createNote({
        attachment,
        content: this.state.content
      });
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <div className="NewNote">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="content">
            <FormControl
              onChange={this.handleChange}
              value={this.state.content}
              componentClass="textarea"
            />
          </FormGroup>
          <FormGroup controlId="file">
            <ControlLabel>Attachment</ControlLabel>
            <FormControl onChange={this.handleFileChange} type="file" />
          </FormGroup>
          <CameraComponent />
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
        </form>
      </div>
    );
  }
}
