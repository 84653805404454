import React, { Component } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { PageHeader, ListGroup, ListGroupItem, Col, Row, Panel} from "react-bootstrap";
import "./Home.css";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      notes: []
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const notes = await this.notes();
      this.setState({ notes });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  notes() {
    return API.get("notes", "/notes");
  }

  renderNotesList(notes) {
    return [{}].concat(notes).map(
      (note, i) =>
        i !== 0
          ? <LinkContainer
              key={note.noteId}
              to={`/notes/${note.noteId}`}
            >
              <ListGroupItem header={note.content.trim().split("\n")[0]}>
                {"Created: " + new Date(note.createdAt).toLocaleString()}
              </ListGroupItem>
            </LinkContainer>
          : <LinkContainer
              key="new"
              to="/notes/new"
            >
              <ListGroupItem>
                <h4>
                  <b>{"\uFF0B"}</b> Create a new Order
                </h4>
              </ListGroupItem>
            </LinkContainer>
    );
  }

  renderOffers() {
    return (
      <div className="latestOffers">
      <Row xs={12}>
        <Panel>
         <Panel.Heading>
           <Panel.Title componentClass="h3">Latest Offers</Panel.Title>
         </Panel.Heading>
         <Panel.Body>
           <Col xs={6} md={6}>
               Offer 1
           </Col>
           <Col xs={6} md={6}>
               Offer 2
           </Col>
         </Panel.Body>
       </Panel>
      </Row>
    </div>
    )
  }

  renderMostViewed() {
    return (
      <div className="mostViewed">
      <Row xs={12}>
        <Panel>
         <Panel.Heading>
           <Panel.Title componentClass="h3">Most Viewed</Panel.Title>
         </Panel.Heading>
         <Panel.Body>
           <Col xs={6} md={6}>
               Top Item 1
           </Col>
           <Col xs={6} md={6}>
               Top Item 2
           </Col>
         </Panel.Body>
       </Panel>
      </Row>
    </div>
    )
  }

  renderSeasons() {
    return (
      <div className="seasonList">
      <Row xs={12}>
        <Col xs={3} md={3}>
          <Panel>
            <Panel.Heading>
              Valentines Day
            </Panel.Heading>
            <Panel.Body>
              Awhhhhh
            </Panel.Body>
          </Panel>
        </Col>
        <Col xs={3} md={3}>
          <Panel>
            <Panel.Heading>
              Easter
            </Panel.Heading>
            <Panel.Body>
              Eggs up!
            </Panel.Body>
          </Panel>
        </Col>
        <Col xs={3} md={3}>
          <Panel>
            <Panel.Heading>
              Halloween
            </Panel.Heading>
            <Panel.Body>
              Spoooooooookkkky
            </Panel.Body>
          </Panel>
        </Col>
        <Col xs={3} md={3}>
          <Panel>
            <Panel.Heading>
              Christmas
            </Panel.Heading>
            <Panel.Body>
              Ho Ho Hooooooo
            </Panel.Body>
          </Panel>
        </Col>
      </Row>
    </div>
    )
  }



  renderLander() {
    return (
      <div className="lander">
        <p>Secret item delivery - every season</p>
        <div>
        {this.renderSeasons()}
        </div>
        <div>
        {this.renderOffers()}
        </div>
        <div>
        {this.renderMostViewed()}
        </div>
      </div>
    );
  }

  renderNotes() {
    return (
      <div className="notes">
        <PageHeader>Personal Orders</PageHeader>
        <ListGroup>
          {!this.state.isLoading && this.renderNotesList(this.state.notes)}
        </ListGroup>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderNotes() : this.renderLander()}
      </div>
    );
  }
}
